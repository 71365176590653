import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Icon,
  Popconfirm,
  Switch,
  Typography,
  Radio
} from "antd";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  getOrdersDashboard,
  getProducts,
  getSettings,
  patchOrder,
  postChangeOrderPayment,
  sendOrderMail
} from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";
import MainPageOrderModal from "../components/MainPageComponents/MainPageOrderModal";
import { setProducts } from "../redux/actions/Actions";

const { Title, Text } = Typography;

class MainPage extends Component {

  interval;

  state = {
    orders: [],
    modalVisible: false,
    modalOrder: {},
    showType: 'today',
    partitionFailedPayment: false
  };

  componentDidMount() {
    this.loadConfig();
    this.refreshStats();
    this.interval = setInterval(this.refreshStats, 20000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadConfig = () => {
    getSettings().then(({ settings, error }) => {
      if (settings) {
        const onlySendAfterSuccessfulPayment = settings.email.find(s => s.key === 'onlySendAfterSuccessfulPayment');
        if (onlySendAfterSuccessfulPayment && onlySendAfterSuccessfulPayment.value === 'true') {
          this.setState({ partitionFailedPayment: true });
        }
      }
    })
  };

  refreshStats = () => {
    getProducts()
      .then(({ products, error }) => {
        if (error) {

        }
        if (products) {
          this.props.setProducts(products);
        }
      })
      .then(getOrdersDashboard)
      .then(({ orders, error }) => {
        if (error) {

        }
        if (orders) {
          orders.forEach(order => {
            order.timeSlot = moment(order.timeSlot);
            order.date = moment(order.date);
            order.paymentMethod = 'contant';
            order.paid = false;
            order.payment.forEach(payment => {
              if (['paid', 'paidout'].includes(payment.status.toLowerCase())) {
                order.paid = true;
                order.paymentMethod = payment.method;
              }
            });
          });

          return this.setState({ orders: orders });
        }
      });

  };

  updateOrderFinished = (order) => {
    const { orders } = this.state;
    const orderIndex = orders.findIndex(o => o._id === order._id);
    if (orderIndex) {
      orders[orderIndex].finished = order.finished;
    }
    this.setState({ orders });
    patchOrder(order).then(() => {
    })
  };

  handleShowType = (event) => {
    this.setState({ showType: event.target.value });
  };

  renderMenuItem = (route, title, text, icon, cardHeadStyle, cardStyle, cardBodyStyle) => (
    <Card headStyle={cardHeadStyle}
      style={cardStyle}
      bodyStyle={cardBodyStyle}
      onClick={() => this.props.history.push(route)}
    >
      <Flex row style={{ width: '100%' }}>
        <Icon type={icon} style={{ fontSize: 64, marginRight: 20 }}/>
        <div>
          <Title style={{ color: cardBodyStyle.color }}>{title}</Title>
          <Text style={{ color: cardBodyStyle.color, fontSize: 18 }}>{text}</Text>
        </div>
      </Flex>
    </Card>
  );

  renderMenu = () => {

    const cardStyle = {
      flexGrow: 1,
      flexBasis: 'calc(25% - 40px)',
      marginBottom: 10,
      marginRight: 40,
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
    };

    const cardHeadStyle = {
      display: 'none'
    };

    const cardBodyStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    };


    return (
      <Flex row style={{ width: '100%' }} wrap>
        <Flex row style={{ width: '100%' }} wrap>
          <Flex justifyContent={'space-between'} style={{ width: '100%', flexDirection: 'row-reverse' }} wrap
          >
            {this.renderMenuItem('/timeslots',
              'Tijdsvakken',
              'Beheer de beschikbaarheid van tijdsvakken',
              'clock-circle',
              cardHeadStyle,
              cardStyle,
              { ...cardBodyStyle, backgroundColor: '#ffb97d', color: '#fff' }
            )}
            {this.renderMenuItem('/orders',
              'Bestellingen',
              'Beheer alle bestellingen van de app',
              'inbox',
              cardHeadStyle,
              cardStyle,
              { ...cardBodyStyle, backgroundColor: '#a618ff', color: '#fff' }
            )}
            {this.renderMenuItem('/products',
              'Producten',
              'Beheer uw assortiment',
              'shop',
              cardHeadStyle,
              cardStyle,
              { ...cardBodyStyle, backgroundColor: '#7dbcff', color: '#fff' }
            )}
          </Flex>
        </Flex>
      </Flex>
    )
  };

  renderOrders = () => {
    const { orders, showType } = this.state;

    let localOrders = [...orders];
    //localOrders = localOrders.filter(order => order.timeSlot.isBetween(moment().startOf('day'), moment().endOf('day')));
    localOrders.sort((a, b) => {
      if (a.timeSlot < b.timeSlot) {
        return 1;
      }
      if (a.timeSlot > b.timeSlot) {
        return -1;
      }
      return 0;
    });
    localOrders.reverse();

    // return [];`

    const orderMap = localOrders.map((order, index) => {
      const isToday = order.timeSlot.isBetween(moment().startOf('day'), moment().endOf('day'));
      let status = order.finished ? 'finished' : (isToday) ? 'today' : 'later';

      if (status === 'today' && this.state.partitionFailedPayment && order.payment && order.payment.length && !order.paid) {
        status = 'unpaid';
      }

      if (!(showType === 'all' || status === showType)) {
        return null;
      }

      const actions = [<Icon type="search" key="setting"
        onClick={() => this.props.history.replace(`/orders/dashboard/${order._id}`)}/>];
      if (status === 'today' || status === 'unpaid') {
        actions.push(
          <Popconfirm
            title="Wilt u de email verzenden naar de printer？"
            okText="Ja"
            cancelText="Nee"
            icon={<Icon type="printer"/>}
            onConfirm={() => {
              sendOrderMail(order._id).then(() => {
              });
            }}>
            <Icon type="printer" key="print"/>
          </Popconfirm>
        );
      }
      if (status !== 'later' && status !== 'unpaid') {
        actions.push(
          <Switch checked={!!order.finished}
            onChange={val => this.updateOrderFinished({ ...order, finished: val ? Date.now() : null })}
            checkedChildren="Klaar"
            unCheckedChildren="Todo"/>
        );
      }
      if (status === 'unpaid' && order.customer) {
        actions.push(
          <Popconfirm
            title="Wilt u de betaling omzetten naar 'bij afhalen'？"
            okText="Ja"
            cancelText="Nee"
            icon={<Icon type="dollar"/>}
            onConfirm={() => {
              postChangeOrderPayment(order._id, order.customer._id, { paymentMethod: 'contant' })
                .then(() => {
                  this.refreshStats();
                });
            }}>
            <Icon type="dollar" key="payment"/>
          </Popconfirm>
        );
      }
      if (!isToday) {
        return null;
      }
      return (
        <Card
          className={`mainpage-order-card mainpage-order-card-${status}`}
          title={(
            <Flex row justifyContent={'space-between'}>
              <Text strong>#{order.orderNr}</Text>
              <Text strong>{order.timeSlot.format('HH:mm')}</Text>
            </Flex>
          )}
          actions={actions}
          key={index}
        >
          <Flex row style={{ width: '100%', height: '100%' }}>
            <Flex grow={2} column style={{ height: '100%' }}>
              {
                this.props.locations ? <>
                  <Text strong>Locatie</Text>
                  <Text>{this.props.locations.find(l => l.key == order.location)?.name || order.location}</Text>
                </> : null
              }
              <Text strong>Besteld op</Text>
              <Text>{order.date.format('DD/MM HH:mm')}</Text>
              <Text strong>Voor</Text>
              <Text>
                {order.customer ? `${order.customer.firstName} ${order.customer.lastName}` : (order.userInfo ? `${order.userInfo.firstName} ${order.userInfo.lastName}` : '')}
              </Text>
              <Text strong>Betaling</Text>
              <Text style={{
                fontWeight: 'bold',
                color: order.paid || !(order.payment && order.payment.length) ? 'green' : 'red'
              }}>{order.payment && order.payment.length ? order.payment[order.payment.length - 1].method : 'bij afhalen'}</Text>
              <Text style={{
                fontWeight: 'bold',
                color: order.paid ? 'green' : 'red'
              }}>{order.paid ? 'Betaald' : (order.payment && order.payment.length ? order.payment[order.payment.length - 1].status : 'Niet betaald')}</Text>
            </Flex>
            <Flex grow={3} row style={{ borderLeft: '1px solid', paddingLeft: 5, height: '100%' }}>
              <Flex column style={{ overflowY: 'auto', maxHeight: 150, width: '100%', minHeight: 150 }}>
                <Text strong style={{ borderBottom: '1px solid' }}>Besteld</Text>
                {
                  order.products.map((product, index) => {

                    return (<Fragment key={index}>
                      <Text strong>{product.name}</Text>
                      {
                        product.options.length > 0 ?
                          <ul style={{ paddingLeft: 20 }}>
                            {product.options.map((option, oIndex) => {
                              if (option.optionText === 'true') {
                                return (
                                  <li key={oIndex}>
                                    <Text>{option.text}</Text>
                                  </li>
                                );
                              }
                              else if (option.optionText !== 'false' && option.optionText !== false && option.optionText !== 'Geen geselecteerd') {
                                return (
                                  <li key={oIndex}>
                                    <Text>{option.optionText}</Text>
                                  </li>
                                )
                              }
                              return (<Fragment key={oIndex}/>)
                            })}
                          </ul>
                          : <Fragment/>
                      }
                    </Fragment>);
                  })
                }
              </Flex>
            </Flex>
          </Flex>

        </Card>
      )
    });

    if (orderMap.filter(element => element !== null).length === 0) {
      return (
        <Flex center>
          <Typography>Voor vandaag zijn nog geen bestellingen van dit type ontvangen</Typography>
        </Flex>
      );
    }

    return orderMap;
  };

  render() {
    const { modalOrder, modalVisible, showType } = this.state;

    const renderedOrders = this.renderOrders();

    return (
      <Fragment>
        {this.renderMenu()}
        <Title>Bestellingen vandaag</Title>
        <Flex row style={{ marginBottom: 20 }}>
          <Radio.Group value={showType} onChange={this.handleShowType}>
            <Radio.Button value="today">Nieuw</Radio.Button>
            <Radio.Button value="finished">Klaar</Radio.Button>
            {
              this.state.partitionFailedPayment ?
                <Radio.Button value="unpaid">Betaling mislukt</Radio.Button> : null
            }
            <Radio.Button value="all">Alles</Radio.Button>
          </Radio.Group>
        </Flex>
        <Flex row className="data-cards" wrap>
          {renderedOrders}
        </Flex>
        <MainPageOrderModal
          order={modalOrder}
          handleClose={() => this.setState({ modalVisible: false })}
          visible={modalVisible}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  products: state.product.list,
  locations: state.settings.locations,
});
const mapDispatchToProps = dispatch => ({
  setProducts: products => dispatch(setProducts(products)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPage));
