import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Icon,
  Popconfirm,
  Switch,
  Typography
} from "antd";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  getOrderById,
  getOrderRender,
  patchOrder,
  patchTimeSlotByTime,
  sendOrderMail
} from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";
import { setProducts } from "../redux/actions/Actions";
import { formatCurrency } from '../lib/formatter';

const { Title, Text } = Typography;

class ProcessOrderPage extends Component {

  state = {
    order: {
      _id: null,
      customer: {},
      date: moment(),
      timeSlot: moment(),
      products: [],
      finished: false,
    },
  };

  componentDidMount() {
    this.loadOrder();
  }

  updateOrderFinished = (order) => {
    this.setState({ order });
    patchOrder(order).then(() => {
    })
  };

  loadOrder = () => {
    getOrderById(this.props.match.params.order_id)
      .then((response) => {
        const orders = response.orders;
        if (orders) {
          orders.timeSlot = moment(orders.timeSlot);
          orders.date = moment(orders.date);
          orders.products.forEach(p => {
            if (p.options && Array.isArray(p.options) && p.options.length > 0) {
              const product = this.props.products.find(pFilter => pFilter._id === p._id);
              if (product) {
                p.options.forEach((opt, index) => {
                  const option = product.options.find(o => o.key === opt.key);
                  p.options[index] = {
                    ...opt,
                    ...option
                  }
                });
              }
            }
          });

          orders.paymentMethod = 'contant';
          orders.paid = false;
          orders.payment.forEach(payment => {
            orders.paymentMethod = payment.method;
            if (['paid', 'paidout'].includes(payment.status.toLowerCase())) {
              orders.paid = true;
            }
          });
          this.setState({ order: orders });
        }
      })

  };

  render() {
    const { order } = this.state;

    const cardStyle = {
      flexGrow: 1,
      flexBasis: 'calc(40% - 40px)',
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 20,
      display: 'flex',
      flexDirection: 'column',
    };

    const cardHeadStyle = {
      display: 'flex',
      alignItems: 'center',
    };

    const cardBodyStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    };

    const cardBodyStyleRow = {
      ...cardBodyStyle,
      flexDirection: 'row',
      justifyContent: 'space-around'
    };

    const status = order.finished ? 'finished' : (order.timeSlot.isBetween(moment().startOf('day'), moment().endOf('day')) ? 'today' : 'later');
    const actions = [];
    if (status === 'today' || status === 'finished') {
      actions.push(
        <Popconfirm
          title="Wilt u de email verzenden naar de printer？"
          okText="Ja"
          cancelText="Nee"
          icon={<Icon type="printer"/>}
          onConfirm={() => {
            sendOrderMail(order._id).then(() => {
            });
            this.updateOrderFinished({ ...order, finished: Date.now() });
          }}>
          <Button type="primary" icon="printer" style={{ marginRight: 20 }}>Verstuur naar printer</Button>
        </Popconfirm>
      );
    }
    if (status === 'today' || status === 'finished') {
      actions.push(
        <Switch checked={!!order.finished}
          onChange={val => this.updateOrderFinished({ ...order, finished: val ? Date.now() : null })}
          checkedChildren="Bestelling afgerond"
          unCheckedChildren="Bestelling in behandeling"
          style={{ marginTop: 4 }}
        />
      );
    }
    if (order.timeSlot > moment()) {
      actions.push(
        <Popconfirm
          title="Wilt u deze order echt verwijderen？"
          okText="Ja"
          cancelText="Nee"
          icon={<Icon type="delete"/>}
          onConfirm={async () => {
            await patchOrder({ _id: order._id, status: 'draft' });
            await patchTimeSlotByTime({ time: order.timeSlot.format('YYYY-MM-DDTHH:mmZ'), used: 0, slots: 1 });
            this.props.history.push(this.props.history.location.pathname.indexOf('dashboard') > -1 ? '/' : '/orders');
          }}>
          <Button type="primary" icon="delete" style={{ marginRight: 20 }}>Verwijder bestelling</Button>
        </Popconfirm>
      );
    }
    actions.push(
      <Popconfirm
        title="Wilt u de email printen？"
        okText="Ja"
        cancelText="Nee"
        icon={<Icon type="printer"/>}
        onConfirm={async () => {
          const getContent = await getOrderRender(order._id);
          const body = document.getElementsByTagName('body')[0];
          const printContainer = document.createElement('div');
          printContainer.id = "print-container";
          printContainer.innerHTML = getContent;
          body.appendChild(printContainer);
          window.print();
          printContainer.remove();

        }}>
        <Button type="primary" icon="printer" style={{ marginRight: 20 }}>Print</Button>
      </Popconfirm>
    );
    const customer = order.userInfo && order.userInfo.firstName ? order.userInfo : order.customer;

    return (
      <Fragment>
        <Flex row justifyContent={'space-between'}>
          <Flex row>
            <Button type='primary' shape='circle' icon={'arrow-left'} style={{ marginRight: 10, marginTop: 8 }}
              onClick={() => this.props.history.push(this.props.history.location.pathname.indexOf('dashboard') > -1 ? '/' : '/orders')}
            />
            <Title>Bestelling {order.orderNr}</Title>
          </Flex>
          <div>
          </div>
        </Flex>
        {
          actions.length > 0 ?
            <Flex row style={{ width: '100%' }} wrap>
              <Flex row style={{ width: '100%' }} wrap>
                <Flex row justifyContent={'space-between'} style={{ width: '100%' }} wrap
                >
                  <Card title="Acties"
                    headStyle={cardHeadStyle}
                    style={cardStyle}
                    bodyStyle={cardBodyStyleRow}
                  >
                    {actions}
                  </Card>
                </Flex>
              </Flex>
            </Flex> : null
        }
        <Flex row style={{ width: '100%' }} wrap>
          <Flex row style={{ width: '100%' }} wrap>
            <Flex row justifyContent={'space-between'} style={{ width: '100%' }} wrap
              basis={'60%'}
              grow={1}
            >
              <Card title="Klantgegevens"
                headStyle={cardHeadStyle}
                style={cardStyle}
                bodyStyle={cardBodyStyle}
              >
                <Text strong>{customer.firstName} {customer.lastName}</Text>
                <Text strong>{customer.address} {customer.city}</Text>
                <Text strong>{customer.phone}</Text>
                <Text strong>{customer.email}</Text>
                <Text strong>Betaald: </Text>
                <Text style={{
                  fontWeight: 'bold',
                  color: order.paid ? 'green' : 'red'
                }}>{order.paid ? 'Ja' : 'Nee'}</Text>
                <Text strong>{order.paymentMethod}</Text>
              </Card>
              <Card title="Bestelgegevens"
                headStyle={cardHeadStyle}
                style={cardStyle}
                bodyStyle={cardBodyStyle}
              >
                <Text>Ordernummer: <Text strong>{order.orderNr}</Text></Text>
                <Text>Tijdsvak: <Text strong>{order.timeSlot.format('DD/MM/YYYY HH:mm')}</Text></Text>
                <Text>Bestelling geplaatst: <Text strong>{order.date.format('DD/MM/YYYY HH:mm')}</Text></Text>
                <Text>Notitie: <Text strong>{order.note}</Text></Text>
              </Card>
            </Flex>
          </Flex>
        </Flex>
        <Flex row style={{ width: '100%' }} wrap>
          <Flex row style={{ width: '100%' }} wrap>
            <Flex row justifyContent={'space-between'} style={{ width: '100%' }} wrap
              basis={'60%'}
            >
              {
                order.products.map(product => (
                  <Fragment key={`${product._id}-${product.productId}-${product.amount}`}>
                    <Card title={`${product.amount}x ${product.name}`}
                      headStyle={cardHeadStyle}
                      style={cardStyle}
                      bodyStyle={cardBodyStyle}
                    >
                      <Text strong>
                        {formatCurrency(product.price)}
                      </Text>

                      {
                        product.options.length > 0 ?
                          <Fragment>
                            <Text>Opties:</Text>
                            <ul style={{ paddingLeft: 20, bulletStyle: 'none' }}>
                              {product.options.map((option, index) => {
                                if (option.optionText === 'true' || option.optionText === true) {
                                  return <li key={index}><Text>{option.text}</Text></li>
                                }
                                else if (option.optionText !== 'Geen geselecteerd' && option.optionText !== 'false' && option.optionText !== false) {
                                  return (

                                    <li key={index}>
                                      <Text>{option.optionText}</Text>
                                    </li>
                                  )
                                }
                                return (<Fragment/>)
                              })}
                            </ul>
                          </Fragment>
                          : <Fragment/>
                      }
                    </Card>
                  </Fragment>
                ))
              }
            </Flex>
          </Flex>
        </Flex>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  products: state.product.list,
});
const mapDispatchToProps = dispatch => ({
  setProducts: products => dispatch(setProducts(products)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProcessOrderPage));
